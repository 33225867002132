import React, { useState, useEffect, useContext } from "react";
import ReactTimeAgo from "react-time-ago";
import { Transition } from "@tailwindui/react";

// Import the functions you need from the SDKs you need
import { db, functions } from "../../firebase";
import {
  useParams,
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { httpsCallable } from "firebase/functions";

// Context
import { AuthContext, UserInputContext } from "../../App";

// Utils
import { loadUserLinks } from "../../utils/utils";

// Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import Cross from "../../assets/Cross.png";

const Profile = (props) => {
  const [loadingUser, setLoadingUser] = useState(true);
  const [isValidUser, setIsValidUser] = useState(false);

  const [profileImageURL, setProfileImageURL] = useState([]);

  const [userLinksLoading, setUserLinksLoading] = useState(true);
  const [userLinks, setUserLinks] = useState([]);
  const [pageLoaded, setPageLoaded] = useState(false);

  // Context
  const authUser = useContext(AuthContext);

  const { username } = useParams();

  useEffect(() => {
    var userRef = db.collection("Usernames").doc(username);
    console.log("Requesting user");
    userRef
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          let data = doc.data();

          // Load user links
          loadUserLinks(data.userID)
            .then((loadedLinks) => {
              setUserLinks([...loadedLinks]);
              setUserLinksLoading(false);
            })
            .catch((error) => {
              console.error("Got error: " + error);
              setUserLinksLoading(false);
            });

          var userIDDoc = await db.collection("Users").doc(data.userID).get();
          setProfileImageURL(userIDDoc.data().photoURL);
          setIsValidUser(true);
        } else {
          setIsValidUser(false);
        }
        setLoadingUser(false);
      })
      .catch((error) => {
        console.log("Error getting data for username: " + username);
      });
  }, [username]);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  }, []);

  if (loadingUser) {
    return (
      <div className="w-full min-h-screen flex flex-col items-center bg-gray-50 p-5 overflow-auto ">
        <div className="max-w-2xl w-full animate-pulse ">
          {/* Header */}
          <div className="flex items-center mb-4">
            {/* Profile */}
            <div className="flex-1 py-6 bg-gray-200 rounded-full mr-3"> </div>
            {/* Username */}
            <div className="flex-1 py-6 w-full bg-gray-200 rounded-full"></div>
            <div className="flex-1 py-6 w-full bg-transparent rounded-full"></div>
            <div className="flex-1 py-6 w-full bg-transparent rounded-full"></div>
          </div>
          {/* Title */}
          <div className="py-6 mb-4 bg-gray-200 rounded-full"></div>
          {/* Image */}
          <div className="bg-gray-200 h-64 mb-4 rounded-2xl"></div>
          {/* Details */}
          <div className="py-6 mb-4 bg-gray-200 rounded-full"></div>
          {/* Checkout */}
          <div className="mt-4 flex justify-center">
            <div className="py-6 w-56 font-bold text-white bg-blue-500 rounded-full bg-gray-200"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!isValidUser) {
    return <Navigate to="/usernotfound" />;
  }

  return (
    <div className="w-full min-h-screen flex flex-col items-center bg-gray-50 p-5 overflow-auto">
      <Transition
        className="max-w-2xl w-full"
        show={pageLoaded}
        enter="transition-opacity ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {/* Head Content */}
        <div className="flex flex-col items-center pt-10 pb-8">
          <img
            className="w-20 h-20 object-cover rounded-full"
            src={profileImageURL}
            alt="pfp"
          />
          <div className="flex flex-col justify-center font-bold text-2xl pt-3">
            {username}'s internet shelf
          </div>
        </div>
        {/* Links */}
        {userLinksLoading ? (
          <div
            role="status"
            className="flex justify-center items-center h-full"
          >
            <svg
              aria-hidden="true"
              className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-900"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3">
            {userLinks.map((link, index) => {
              console.log(link);
              return (
                <div
                  key={index}
                  className="flex flex-col justify-between items-center bg-white  rounded-xl overflow-hidden shadow-md cursor-pointer transition-all transform-gpu duration-50 ease-in hover:scale-110"
                  onClick={() => window.open(`${link.shelfUrl}`, "_blank")}
                >
                  <div className="flex items-center justify-center h-full">
                    <img
                      className="block h-full object-contain"
                      alt="thumbnail"
                      src={link.imageUrl}
                    />
                  </div>
                  {/* <div className="px-3 py-4 flex w-full justify-center border-t border-gray-200">
                    <h3 className="text-gray-900 font-semibold text-s line-clamp-2">
                      {link.productTitle}
                    </h3>
                  </div> */}
                </div>
              );
            })}
          </div>
        )}
        <Footer />
      </Transition>
    </div>
  );
};

export default Profile;
