import React, { useState, useEffect, useContext } from "react";
import "./index.css";

// Import the functions you need from the SDKs you need
import { db, functions } from "../../firebase";
import { Navigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";

// Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// Context
import { AuthContext, UserInputContext } from "../../App";

import { loadUserLinks } from "../../utils/utils";

const Main = (props) => {
  // Input
  const [linkInput, setLinkInput] = useState("");
  const [shelfLinkOutput, setShelfLinkOutput] = useState("");
  const [loadingLink, setLoadingLink] = useState(false);

  // Context
  const authUser = useContext(AuthContext);
  const { phone, venmo, username, cashapp } = useContext(UserInputContext);
  const [phoneValue, setPhoneValue] = phone;
  const [venmoValue, setVenmoValue] = venmo;
  const [cashappValue, setCashappValue] = cashapp;
  const [usernameValue, setUsernameValue] = username;

  // Sign up user details
  const [enteringInput, setEnteringInput] = useState(false);
  const [phoneInput, setPhoneInput] = useState(phoneValue ?? "");
  const [venmoInput, setVenmoInput] = useState(venmoValue ?? "");
  const [cashappInput, setCashappInput] = useState(cashappValue ?? "");

  const [phoneErrors, setPhoneErrors] = useState("");
  const [venmoErrors, setVenmoErrors] = useState("");
  const [cashappErrors, setCashappErrors] = useState("");

  const [submitLoading, setSubmitLoading] = useState(false);

  // Content
  const [userLinksLoading, setUserLinksLoading] = useState(true);
  const [userLinks, setUserLinks] = useState([]); // {imageUrl: , productTitle: , shelfUrl: , timestamp: }
  // const [userPurchases, setUserPurchases] = useState([]); // {imageUrl: , productTitle: , shelfUrl: , referrer:, timestamp: }

  async function handlePaymentSubmit(event) {
    event.preventDefault();

    let failed = false;

    setPhoneErrors("");
    var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!phoneInput) {
      setPhoneErrors("Please enter a Phone Number");
      failed = true;
    } else if (!regex.test(phoneInput)) {
      setPhoneErrors("Please enter a valid Phone Number");
      failed = true;
    }

    setVenmoErrors("");
    setCashappErrors("");
    if (!venmoInput && !cashappInput) {
      setVenmoErrors("Please enter either Venmo or Cashapp");
      setCashappErrors("Please enter either Venmo or Cashapp");
      failed = true;
    }

    if (failed) {
      return;
    }

    setSubmitLoading(true);
    httpsCallable(
      functions,
      "addUser"
    )({ phone: phoneInput, venmo: venmoInput, cashapp: cashappInput })
      .then(() => {
        setSubmitLoading(false);
        setPhoneValue(phoneInput);
        setVenmoValue(venmoInput);
        setCashappValue(cashappInput);
        setEnteringInput(false);
      })
      .catch((error) => {
        console.log("Got error: " + error);
        setSubmitLoading(false);
      });
  }

  async function loadUserPurchases(user) {
    var linksRef = db.collection("Links");

    var userPurchasesRef = db
      .collection("Users")
      .doc(user.uid)
      .collection("Purchases")
      .orderBy("purchasedTimestamp", "desc");
    const userPurchasesRefSnapshot = await userPurchasesRef.get();
    let userPurchasesPromises = userPurchasesRefSnapshot.docs.map((doc) => {
      const linkRef = linksRef.doc(doc.data().shelfID);
      return linkRef.get().then((linkDoc) => {
        if (!linkDoc.exists) {
          throw new Error("Link does not exist");
        }
        let linkProductData = linkDoc.data().productData;
        return {
          imageUrl: linkProductData.imageUrl,
          productTitle: linkProductData.productTitle,
          shelfUrl: "https://getshelf.app/product/" + linkRef.id,
          referrer: linkDoc.data().userDisplayName,
          timestamp: doc.data().purchasedTimestamp,
        };
      });
    });
    return await Promise.all(userPurchasesPromises);
  }

  async function handleLinkSubmit(event) {
    event.preventDefault();
    setLoadingLink(true);
    setShelfLinkOutput("");
    httpsCallable(
      functions,
      "makeShelfLink"
    )({ url: linkInput })
      .then(async (shelfId) => {
        if (!shelfId.data) {
          setShelfLinkOutput("Please enter a valid Amazon product URL");
          setLoadingLink(false);
          return;
        }
        console.log(JSON.stringify(shelfId));
        const shelfLink = "https://getshelf.app/product/" + shelfId.data;
        setShelfLinkOutput(shelfLink);
        setLoadingLink(false);
      })
      .catch((error) => {
        console.error("Error running function with error: " + error);
      });
  }

  async function copyToClipboard(link) {
    if (link !== "") {
      await navigator.clipboard.writeText(link);
      alert("Copied Link!");
    }
  }

  useEffect(() => {
    if (!authUser) {
      return;
    }
    loadUserLinks(authUser.uid)
      .then((loadedLinks) => {
        setUserLinks([...loadedLinks]);
        setUserLinksLoading(false);
      })
      .catch((error) => {
        console.error("Got error: " + error);
        setUserLinksLoading(true);
      });

    loadUserPurchases(authUser)
      .then((loadedPurchases) => {
        // setUserPurchases([...loadedPurchases]);
      })
      .catch((error) => {
        console.error("Got error: " + error);
      });
  }, [props, authUser]);

  if (!authUser) {
    return <Navigate to="/login" />;
  }

  if (!usernameValue) {
    // Go to login to sign up for username
    return <Navigate to="/login" />;
  }

  // Updating payment method
  if (enteringInput) {
    // Fill out user input
    return (
      <div className="w-full h-screen bg-stone-100">
        <Header />
        <div className="md:grid md:grid-cols-2 md:gap-5 p-5">
          <form className="p-5 bg-white border border-gray-200 rounded-3xl">
            <p className="text-3xl tracking-tight font-extrabold mb-5 text-gray-900">
              Payment Details
            </p>
            <div className="mb-3">
              <label className="block mb-2 text-lg font-medium text-gray-900 ">
                Phone number
              </label>
              <input
                type="text"
                className={
                  phoneErrors
                    ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
                    : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                }
                placeholder="Phone Number"
                value={phoneInput}
                onChange={(e) => {
                  setPhoneInput(e.target.value.trim());
                }}
                required
              />
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {phoneErrors}
              </p>
            </div>

            <div className="mb-3">
              <label className="block mb-2 text-lg font-medium text-gray-900 ">
                Venmo
              </label>
              <input
                type="text"
                className={
                  venmoErrors
                    ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
                    : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                }
                placeholder="Venmo Username"
                value={venmoInput}
                onChange={(e) => {
                  setVenmoInput(e.target.value.trim());
                }}
                required
              />
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {venmoErrors}
              </p>
            </div>

            <div className="mb-5">
              <label className="block mb-2 text-lg font-medium text-gray-900 ">
                Cash App
              </label>
              <input
                className={
                  cashappErrors
                    ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
                    : "mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                }
                type="text"
                placeholder="Cashtag"
                value={cashappInput}
                onChange={(e) => {
                  setCashappInput(e.target.value.trim());
                }}
                required
              />
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {cashappErrors}
              </p>
            </div>

            <div className="mt-6 w-auto">
              <button
                className="mr-3 secondary-button dashboard-form-button bg-gray-100 hover:bg-gray-300 py-3 px-5 rounded-full focus:ring-4 focus:ring-gray-200 focus:border-gray-300"
                onClick={() => setEnteringInput(false)}
              >
                Cancel
              </button>
              <button
                className="secondary-button text-white font-semibold dashboard-form-button bg-lime-500 hover:bg-lime-400 py-3 px-5 rounded-full focus:ring-4 focus:ring-lime-200 focus:border-lime-300"
                onClick={handlePaymentSubmit}
                disabled={submitLoading}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-gray-100 w-full sm:h-screen h-full">
      <Header />
      <div className="flex-1 w-full md:grid md:grid-cols-2 md:gap-5 p-5">
        {/* Form */}
        <form
          // className="h-full overflow-auto p-6 bg-white border border-gray-200 rounded-3xl"
          className="max-w-screen-xl w-full mx-auto p-6 bg-white border border-gray-200 rounded-3xl h-fit"
          onSubmit={handleLinkSubmit}
        >
          <p className="text-3xl tracking-tight font-extrabold mb-8 text-gray-900">
            Create Link
          </p>
          <div className="mb-8">
            <label className="block mb-2 text-lg font-medium text-gray-900 ">
              1. Paste Amazon or Shopify link 🔗
            </label>
            <input
              className="mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Product URL"
              type="text"
              value={linkInput}
              onChange={(e) => setLinkInput(e.target.value)}
            />
            <div className="flex items-center">
              <button
                type="submit"
                className="text-white bg-lime-500 hover:bg-lime-400 focus:ring-4 focus:outline-none focus:ring-lime-300 font-semibold rounded-full text-base sm:w-auto px-5 py-2.5 text-center flex h-full"
              >
                {loadingLink ? "Converting..." : "Generate link"}
              </button>
              {loadingLink ? 
                  <svg
                    aria-hidden="true"
                    className="inline w-7 h-7 text-gray-200 animate-spin dark:text-white-600 fill-gray-900 ml-2"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                : null}
              </div>
          </div>

          <div className="mb-8">
            <label className="block mb-2 text-lg font-medium text-gray-900 ">
              2. Copy Shelf link 🎉
            </label>
            <input
              id="ShelfLinkOutput"
              placeholder="Copy this URL!"
              type="text"
              value={shelfLinkOutput === "" ? "" : shelfLinkOutput}
              onClick={() => copyToClipboard(shelfLinkOutput)}
              readOnly="readonly"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            />
          </div>

          <div className="mb-8">
            <label className="block mb-2 text-lg font-medium text-gray-900 ">
              3. Share online! 🚀
            </label>
          </div>

          <div className="mb-8">
            <label className="block mb-2 text-lg font-medium text-gray-900 ">
              4. Get paid! 💰
            </label>
            <button
              className="text-gray-800 bg-gray-300 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 font-semibold rounded-full text-base w-full sm:w-auto px-5 py-2.5 text-center"
              onClick={() => setEnteringInput(true)}
            >
              Update payment method
            </button>
          </div>
        </form>
        {/* Links */}
        <div className="p-6 bg-white border border-gray-200 rounded-3xl flex flex-col  sm:h-[650px] mt-5 md:mt-0">
          <p className="text-3xl tracking-tight font-extrabold mb-8 text-gray-900">
            Links
          </p>
          {userLinksLoading ? (
            <div
              role="status"
              className="flex justify-center items-center h-full"
            >
              <svg
                aria-hidden="true"
                className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-900"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div className=" flex-1 sm:overflow-auto overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 min-w-xs">
                <tbody className="">
                  {userLinks.map((link, index) => {
                    return (
                      <tr key={index} className="w-full overflow-auto">
                        <td className="py-2 w-auto sm:w-14 object-contain">
                          <div className="h-14 sm:h-14 w-14 overflow-hidden">
                            <img
                              className="h-full w-full object-cover"
                              alt="thumbnail"
                              src={link.imageUrl}
                            />
                          </div>
                        </td>
                        <td className=" py-2 px-5 overflow-hidden overflow-ellipsis whitespace-nowrap max-w-xs text-gray-900 text-sm">
                          {link.productTitle}
                        </td>
                        <td
                          className="py-2 text-blue-500 hover:text-blue-700 cursor-pointer hover:underline min-w-s"
                          onClick={() =>
                            window.open(`${link.shelfUrl}`, "_blank")
                          }
                        >
                          View Link
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
