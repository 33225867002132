// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1dGa_v6ctM0TfkMZASjISVO2o7lmSi-c",
  authDomain: "getshelf.app",
  projectId: "shelf-c925d",
  storageBucket: "shelf-c925d.appspot.com",
  messagingSenderId: "905566464523",
  appId: "1:905566464523:web:47c2a0f9496cc97d23750e",
  measurementId: "G-C3LQ6E7TBR"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = firebase.firestore();
export const functions = getFunctions(app);