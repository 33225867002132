import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import "./index.css";

const Footer = () => {

  let navigate = useNavigate();

  return (
    <div className="flex p-10 mt-10 items-center w-full justify-center">
      <div className="mr-2 flex cursor-pointer" 
          onClick={() => {
              navigate("/");
           }
      }>
        <div className="bg-lime-400">
          <svg
            className="h-7 w-7 text-white"
            width="3571"
            height="3199"
            viewBox="0 0 3571 3199"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1785.75 2919.43L1785.75 279.538"
              stroke="black"
              strokeWidth="558"
              strokeLinecap="square"
            />
            <path
              d="M3105 602.905L465.111 2595.26"
              stroke="black"
              strokeWidth="558"
              strokeLinecap="square"
            />
            <path
              d="M465.109 602.339L3105 2594.69"
              stroke="black"
              strokeWidth="558"
              strokeLinecap="square"
            />
          </svg>
        </div>
        <p className="font-medium text-lg ml-2">Shelf</p>
      </div>
    </div>
  );
};

export default Footer;
