import { db, functions, auth } from "../firebase";

export async function loadUserLinks(userID) {
    var linksRef = db.collection("Links");

    var userLinksRef = db
        .collection("Users")
        .doc(userID)
        .collection("Links")
        .orderBy("creationTimestamp", "desc");
    const userLinksRefSnapshot = await userLinksRef.get();
    let userLinksPromises = userLinksRefSnapshot.docs.map((doc) => {
        const linkRef = linksRef.doc(doc.id);
        return linkRef.get().then((linkDoc) => {
        if (!linkDoc.exists) {
            throw new Error("Link does not exist");
        }
        let linkProductData = linkDoc.data().productData;
        return {
            imageUrl: linkProductData.imageUrl,
            productTitle: linkProductData.productTitle,
            shelfUrl: "https://getshelf.app/product/" + linkRef.id,
            timestamp: doc.data().creationTimestamp,
        };
        });
    });
    return await Promise.all(userLinksPromises);
}