import React, { useState, useEffect, useContext } from "react";
import { Transition } from "@tailwindui/react";

import "../Main/index.css";
import "./index.css";

// Import the functions you need from the SDKs you need
import { db, functions } from "../../firebase";
import {
  useParams,
  Navigate,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import { httpsCallable } from "firebase/functions";

// Component
import CheckMarkIcon from "../../assets/CheckMarkIcon";
import Footer from "../../components/Footer";

// Context
import { AuthContext } from "../../App";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const previousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="relative w-full shadow bg-white rounded-2xl overflow-hidden flex justify-center">
      <div className="relative h-96 md:h-96 md:w-96 flex justify-center">
        <div className="duration-700 ease-in-out flex items-center">
          <img
            className="block max-w-full max-h-full"
            src={images[currentIndex]}
            alt="Carousel"
          />
        </div>
      </div>

      {images.length > 1 ? (
        <>
          {/* Previous Button */}
          <button
            type="button"
            className="hover:bg-black/10 transition ease-in-out delay-100 absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            onClick={previousImage}
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full">
              <svg
                className="w-4 h-4 text-gray-800 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
              <span className="sr-only">Previous</span>
            </span>
          </button>
          {/* Next Button */}
          <button
            type="button"
            className="hover:bg-black/10 transition ease-in-out delay-100 absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            onClick={nextImage}
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full ">
              <svg
                className="w-4 h-4 text-gray-800 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="sr-only">Next</span>
            </span>
          </button>
        </>
      ) : null}
    </div>
  );
};

const ProductDetails = (props) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  if (props.text !== "") {
    return (
      <div
        className=" p-4 bg-white mt-5 rounded-2xl border border-gray-200"
        style={{ cursor: "pointer" }}
      >
        <div onClick={toggleDetails}>
          <div className="flex justify-between items-center">
            <p className="text-xl font-bold">Details</p>
            <button
              type="button"
              className={
                (showDetails ? "transform rotate-90 " : "transform rotate-0 ") +
                "transition ease-in-out delay-5 h-fit text-white bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-xl text-sm p-2 text-center inline-flex items-center"
              }
            >
              <svg
                className="w-4 h-4 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </button>
          </div>

          {showDetails && (
            <p className="mt-5 font-regular text-lg">{props.text}</p>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const Product = (props) => {
  const [loading, setLoading] = useState(true);
  const [isValidLink, setIsValidLink] = useState(true);
  const { shelfId } = useParams();

  const [imageURL, setImageURL] = useState([]);
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState(null);
  const [productTitle, setProductTitle] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [userName, setUserName] = useState("");
  const [profileImageURL, setProfileImageURL] = useState("");

  const [loadingBuy, setLoadingBuy] = useState(false);
  const [queryParams] = useSearchParams();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  // Context
  const authUser = useContext(AuthContext);

  let navigate = useNavigate();

  useEffect(() => {
    // // get data from firestore
    var linkRef = db.collection("Links").doc(shelfId);
    linkRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          console.log(data);
          setImageURL(data.productData.imageUrl);
          setPrice(data.productData.price);
          setCurrency(data.productData.currency ? data.productData.currency.toUpperCase() : "USD");
          setProductTitle(data.productData.productTitle);
          setUserName(data.userDisplayName);
          setProfileImageURL(data.profileImageURL);
          setProductDescription(data.productData.productDescription);
        } else {
          console.log("Could not get data for link ID: " + shelfId);
          setIsValidLink(false);
        }
        if (queryParams.get("session_id")) {
          // Check for success payment session
          httpsCallable(
            functions,
            "paySuccess"
          )({ sessionId: queryParams.get("session_id") })
            .then((sessionId) => {
              console.log("Got back pay success session: " + sessionId.data);
              if (sessionId.data) {
                setPaymentSuccess(true);
              } else {
                setPaymentSuccess(false);
              }
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error running function with error: " + error);
            });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error getting data for link ID: " + shelfId);
      });
  }, [props, queryParams, shelfId, authUser]);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  }, []);

  // let params = queryParams.get("session_id")
  //   ? `?session_id=${queryParams.get("session_id")}`
  //   : "";

  if (!isValidLink) {
    return <Navigate to="/productnotfound" />;
  }

  if (loading) {
    return (
      <div className="w-full min-h-screen flex flex-col items-center bg-gray-50 p-5 overflow-auto ">
        <div className="max-w-2xl w-full animate-pulse ">
          {/* Header */}
          <div className="flex items-center mb-4">
            {/* Profile */}
            <div className="flex-1 py-6 bg-gray-200 rounded-full mr-3"> </div>
            {/* Username */}
            <div className="flex-1 py-6 w-full bg-gray-200 rounded-full"></div>
            <div className="flex-1 py-6 w-full bg-transparent rounded-full"></div>
            <div className="flex-1 py-6 w-full bg-transparent rounded-full"></div>
          </div>
          {/* Title */}
          <div className="py-6 mb-4 bg-gray-200 rounded-full"></div>
          {/* Image */}
          <div className="bg-gray-200 h-64 mb-4 rounded-2xl"></div>
          {/* Details */}
          <div className="py-6 mb-4 bg-gray-200 rounded-full"></div>
          {/* Checkout */}
          <div className="mt-4 flex justify-center">
            <div className="py-6 w-56 font-bold text-white bg-blue-500 rounded-full bg-gray-200"></div>
          </div>
        </div>
      </div>
    );
  }

  if (paymentSuccess) {
    // Show thank you page
    return (
      <div className="app-root">
        <div className="product-root product-root-secondary">
          <p className="product-header-text product-title-secondary ">
            Thank you, from Shelf!
          </p>
          <p className="product-subtitle">Your order is on the way...</p>
          <img className="product-image-secondary" src={imageURL} alt="new" />
          <div
            className="product-footer-container"
            onClick={() => {
              navigate("/");
            }}
          >
            Powered by Shelf
          </div>
        </div>
      </div>
    );
  } else if (queryParams.get("session_id")) {
    // replace to without query params
  }

  return (
    <div className="w-full min-h-screen flex flex-col items-center bg-gray-50 p-5 overflow-auto">
      <Transition
        className="max-w-2xl w-full"
        show={pageLoaded}
        enter="transition-opacity ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex items-center">
          <img
            className="w-12 h-12 rounded-full mr-3 border border-gray-200"
            src={profileImageURL}
            alt="pic"
          />
          <Link
            className="text-lg"
            to={`/profile/${userName}`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="font-bold text-lg">{userName}</span> shared this
            product
          </Link>
          <CheckMarkIcon
            className="w-4 h-4 ml-2 text-blue-500"
            fill="#0074e8"
          />
        </div>

        <div className="my-5 overflow-hidden">
          <p className="text-3xl font-extrabold line-clamp-2">{productTitle}</p>
        </div>

        <ImageCarousel images={imageURL} />
        <ProductDetails text={productDescription} />

        <div className="mt-5 flex justify-center">
          <button
            className="shadow px-4 w-full text-white bg-blue-500 hover:bg-blue-400 focus:ring-4 focus:ring-blue-300 font-bold rounded-2xl text-base px-5 h-12"
            disabled={loadingBuy}
            onClick={() => {
              setLoadingBuy(true);
              httpsCallable(
                functions,
                "payRequest"
              )({ shelfID: shelfId })
                .then((sessionUrl) => {
                  console.log("Got back " + sessionUrl.data);
                  window.location.href = sessionUrl.data;
                  setLoadingBuy(false);
                })
                .catch((error) => {
                  console.error("Error running function with error: " + error);
                });
            }}
          >
          {loadingBuy ? 
                  <svg
                    aria-hidden="true"
                    className="inline w-7 h-7 text-gray-200 animate-spin dark:text-white-600 fill-gray-900 mr-2"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                : <div>Buy now <span className="font-light">—</span> {parseFloat(price).toLocaleString('en-US', {
                    style: 'currency',
                    currency: currency,
                    currencyDisplay: 'narrowSymbol',
                    useGrouping: true
                  })}</div>
            }
          </button>
        </div>
        {/* <div className="flex justify-center">
          {authUser ? (
            <p
              className="mt-8 cursor-pointer text-blue-500 justify-center flex hover:underline"
              onClick={() => {
                navigate("/");
              }}
            >
              View My Shelf
            </p>
          ) : (
            <p
              className="mt-8 cursor-pointer text-blue-500 justify-center flex hover:underline"
              onClick={() => {
                navigate("/");
              }}
            >
              Create A Shelf
            </p>
          )}
        </div> */}
        <Footer />
      </Transition>
    </div>
  );
};

export default Product;
