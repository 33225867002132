import React, { useState, useEffect, createContext } from "react";
// import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { auth, db } from "./firebase";
import {
  getRedirectResult,
  // GoogleAuthProvider
} from "firebase/auth";

// Components
import Landing from "./pages/Landing/landing";
import Main from "./pages/Main/main";
import Product from "./pages/Product/product";
import Profile from "./pages/Profile/profile";

// Styling
import "./App.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
// const stripePromise = loadStripe(
//   "pk_live_51JyvgOANb0LBpbzQ1n2Suh7fnNCJsXjCrP76pv5alkhhwyFba81XDcy6xu3ylrY1jGRhMauq2kFgvTTQjOadgMDd00LySKFhpV"
// );

export const AuthContext = createContext(null);
export const UserInputContext = createContext();

// signOut(auth)
export default function App() {
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [usernameValue, setUsernameValue] = useState(null);
  const [phoneValue, setPhoneValue] = useState(null);
  const [venmoValue, setVenmoValue] = useState(null);
  const [cashAppValue, setCashAppValue] = useState(null);

  function updatedAuthUser(fbUser) {
    if (!fbUser) {
      setLoading(false);
      return;
    }
    var usersRef = db.collection("Users");
    var userRef = usersRef.doc(fbUser.uid);
    userRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          if (data.phone) {
            setPhoneValue(data.phone);
          }
          if (data.venmo) {
            setVenmoValue(data.venmo);
          }
          if (data.username) {
            setUsernameValue(data.username);
          }
          if (data.cashapp) {
            setCashAppValue(data.cashapp);
          }
        } else {
          console.log("Doesn't exist yet");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  // signOut(auth)
  useEffect(() => {
    console.log("Running useeffect");

    getRedirectResult(auth)
      .then((result) => {
        if (!result) {
          console.log("No result");
          return;
        }
        // This gives you a Google Access Token. You can use it to access Google APIs.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        console.log("Getredirectresult: " + user);
        setAuthUser(user);
        updatedAuthUser(user);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        console.log(error);
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });

    const unlisten = auth.onAuthStateChanged((authUser) => {
      console.log("Authstatechanged: " + authUser);
      if (authUser) {
        setAuthUser(authUser);
      } else {
        setAuthUser(null);
      }
      updatedAuthUser(authUser);
    });
    return () => {
      unlisten();
    };
  }, []);

  if (loading) {
    return <div></div>;
  }

  // Use history or URL parameter for redirect?
  return (
    <BrowserRouter>
      <AuthContext.Provider value={authUser}>
        <UserInputContext.Provider
          value={{
            username: [usernameValue, setUsernameValue],
            phone: [phoneValue, setPhoneValue],
            venmo: [venmoValue, setVenmoValue],
            cashapp: [cashAppValue, setCashAppValue],
          }}
        >
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/login" element={<Landing />} />
            <Route path="/login/:shelfId" element={<Landing />} />
            <Route path="/product/:shelfId" element={<Product />} />
            <Route path="/productnotfound" element={<div>Product Not Found :(</div>} />
            <Route path="/usernotfound" element={<div>User Not Found :(</div>} />
            <Route path="/profile/:username" element={<Profile />} />
            <Route path="*" element={<Main />} />
          </Routes>
        </UserInputContext.Provider>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}
