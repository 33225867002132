import React, { useContext, Fragment } from "react";
import { AuthContext, UserInputContext } from "../../App";
import { auth, provider } from "../../firebase";
import { signInWithRedirect } from "firebase/auth";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

// Libs
import { Menu, Transition } from "@headlessui/react";

import "./index.css";

const Header = () => {
  const authUser = useContext(AuthContext);
  const { username } = useContext(UserInputContext);
  const [usernameValue, setUsernameValue] = username;

  async function handleSignupSubmit(event) {
    event.preventDefault();
    signInWithRedirect(auth, provider);
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const route = useLocation();
  let navigate = useNavigate();

  // const navigation = [
  //   { name: "Home", href: "/", current: route.pathname === "/" },
  //   {
  //     name: "Payment",
  //     href: "/payment",
  //     current: route.pathname === "/payment",
  //   },
  // ];
  const userNavigation = [
    { name: "Profile", href: () => navigate(`/profile/${usernameValue}`) },
    { name: "Sign out", href: () => auth.signOut() },
  ];

  if (!authUser) {
    return (
      <nav className="bg-transparent fixed w-full z-20 top-0 left-0 p-5">
        <div className="bg-white border border-gray-300 rounded-full max-w-screen-xl w-full flex flex-wrap items-center justify-between mx-auto p-4 rounded-full">
          <div className="bg-lime-400 ml-2">
            <svg
              className="h-10 w-10 text-white"
              width="3571"
              height="3199"
              viewBox="0 0 3571 3199"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1785.75 2919.43L1785.75 279.538"
                stroke="black"
                strokeWidth="558"
                strokeLinecap="square"
              />
              <path
                d="M3105 602.905L465.111 2595.26"
                stroke="black"
                strokeWidth="558"
                strokeLinecap="square"
              />
              <path
                d="M465.109 602.339L3105 2594.69"
                stroke="black"
                strokeWidth="558"
                strokeLinecap="square"
              />
            </svg>
          </div>
          <div className="flex md:order-2">
            <button
              onClick={(e) => handleSignupSubmit(e)}
              type="button"
              className="text-white bg-pink-600 hover:bg-pink-500 focus:ring-4 focus:outline-none focus:ring-pink-300 font-bold rounded-full text-base px-5 py-3 text-center mr-3 md:mr-0"
            >
              Login
            </button>
          </div>
        </div>
      </nav>
    );
  } else {
    return (
      <div className="bg-transparent block  w-full px-5 pt-5">
        <div className=" bg-white border border-gray-300 rounded-full  w-full flex flex-wrap items-center justify-between mx-auto p-3 rounded-full">
          <div className="bg-lime-400 ml-2">
            <svg
              className="h-8 w-8 text-white"
              width="3571"
              height="3199"
              viewBox="0 0 3571 3199"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1785.75 2919.43L1785.75 279.538"
                stroke="black"
                strokeWidth="558"
                strokeLinecap="square"
              />
              <path
                d="M3105 602.905L465.111 2595.26"
                stroke="black"
                strokeWidth="558"
                strokeLinecap="square"
              />
              <path
                d="M465.109 602.339L3105 2594.69"
                stroke="black"
                strokeWidth="558"
                strokeLinecap="square"
              />
            </svg>
          </div>
          <div className="flex">
            {/* {navigation.map((item, index) => (
              <button
                className={classNames(
                  item.current
                    ? "bg-gray-300 text-gray-900"
                    : "text-gray-600 hover:bg-gray-400 hover:text-gray-800",
                  "rounded-md px-3 py-2 text-sm font-medium ml-5"
                )}
                onClick={() => console.log("hi")}
              >
                {item.name}
              </button>
            ))} */}
            <Menu as="div" className="relative ml-5 items-center">
              <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-4 focus:ring-lime-500">
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-9 w-9 rounded-full"
                  src={authUser.photoURL}
                  alt=""
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <button
                          onClick={item.href}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                          )}
                        >
                          {item.name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    );
  }
};

export default Header;
