import * as React from "react";

const CheckMarkIcon = (props) => (
  <svg
    fill="current"
    height={16}
    width={16}
    viewBox="0 0 24 24"
    aria-label="Merchant verification badge icon"
    {...props}
  >
    <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm5.56 10.06-6 6a1.5 1.5 0 0 1-2.12 0l-3-3a1.5 1.5 0 0 1 0-2.12 1.5 1.5 0 0 1 2.12 0l1.94 1.94 4.94-4.94a1.5 1.5 0 0 1 2.12 0 1.5 1.5 0 0 1 0 2.12z" />
  </svg>
);

export default CheckMarkIcon;
